import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import UserStore from "../../store/user-store";
import { useEffect, useState } from "react";
import { increasePartnerCounter } from "./increasePartnerCounter";

const apiUrl = process.env.REACT_APP_API_URL;

const fetchPartners = async () => {
    const response = await axios.get(`${apiUrl}/v2/miniapp/get_partners/`, {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
    });
    return response.data;
};

const fetchTrial = async () => {
    const response = await axios.get(
        `${apiUrl}/v2/miniapp/trial_partner?user_id=${UserStore.userId}&partner_id=${UserStore.partnerId}`,
        {
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
            },
        }
    );
    return response.data;
};

export const usePartnerList = (tgStartParam) => {
    const [isLoading, setIsLoading] = useState(!tgStartParam?.startsWith('wallet') && tgStartParam !== undefined);

    console.log(tgStartParam)
    const { data: partnerData, isLoading: isPartnersListLoading } = useQuery({
        queryKey: ["partners"],
        queryFn: fetchPartners,
        enabled: Boolean(!UserStore.partnerId && (!tgStartParam?.startsWith('wallet') && tgStartParam !== undefined)),
    });

    useEffect(() => {
        if (!isPartnersListLoading && partnerData) {
            const targetPartner = Object.entries(partnerData).find(
                ([, value]) => value === tgStartParam
            );

            if (targetPartner) {
                UserStore.setPartner(targetPartner[0]);
                increasePartnerCounter();
            }
        }
    }, [partnerData, isPartnersListLoading, tgStartParam]);

    const { data: trialData, isLoading: isTrialLoading } = useQuery({
        queryKey: ["isTrial"],
        queryFn: fetchTrial,
        enabled: Boolean(
            !isPartnersListLoading && partnerData && UserStore.partnerId && UserStore.userId
        ),
    });

    useEffect(() => {
        if (!isTrialLoading && trialData) {
            UserStore.setIsTrailActivated(trialData.can_activate);
            setIsLoading(false);
        }
    }, [trialData, isTrialLoading]);

    return { isLoading };
};
