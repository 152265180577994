import axios from "axios";
import UserStore from "../../store/user-store";
import {useQuery} from "@tanstack/react-query";
import userStore from "../../store/user-store";
import {useState} from "react";
import {renderToStaticMarkup} from "react-dom/server";
import {getUserPlans} from "./Plans/useGetPlans";
const apiUrl = process.env.REACT_APP_API_URL;


const fetchMerge = async (wallet, user_id) => {

    const body = {
        "dapp_user_id": wallet,
        "tg_user_id": user_id
    }

    return axios.post(`${apiUrl}/v2/miniapp/merge_dapp_user`, body, {
        headers: {Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`}
    })
}






export const useFetchMerge = (wallet, userId) => {
    const { data, isLoading } = useQuery({
        queryKey: ['fetch Merge'],
        queryFn: () => fetchMerge(wallet, userId),
        select: (response) => response.data,
        enabled: Boolean(wallet && userId),
        onSuccess: () => getUserPlans(),
        retry: 0
    });


    return { data, isLoading };
};