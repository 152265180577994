import '../src/App.css';
import {Footer} from '../src/components/Footer/Footer';
import {Routes, Route, useLocation} from 'react-router-dom'
import {Homepage} from '../src/pages/Homepage';
import {Friendspage} from '../src/pages/Friendspage';
import {Taskspage} from '../src/pages/Taskspage';
import {Vpnpage} from '../src/pages/Vpnpage';
import Onboardingpage from './pages/Onboardingpage';
import React, {useEffect, useState} from 'react';
import {TonConnectUIProvider} from '@tonconnect/ui-react';
import Lottie from "react-lottie";
import processingAnimation from "./components/Lottie/processingAnimation.json";
import userStore from "./store/user-store";
import UserStore from "./store/user-store";
import {observer} from "mobx-react-lite";
import {usePartnerList} from "./Hooks/Api/usePartnerList";
import {increasePartnerCounter} from "./Hooks/Api/increasePartnerCounter";
import {Settings} from "./components/Settings/Settings";
import {injectStores} from '@mobx-devtools/tools';
import PaymnetStore from "./store/paymnet-store";
import {RouterPage} from "./pages/RouterPage";
import RouterStore from "./store/router-store";
import {useFetchMerge} from "./Hooks/Api/useFetchMerge";

const App = observer(() => {
    const TG_WEB_APP = window.Telegram.WebApp
    const [isOnboarding, setOnboarding] = useState(localStorage.getItem('onboarding'))
    const [userId, setUserId] = useState();
    const [userPlanData, setUserPlanData] = useState(localStorage.getItem('plan_data'));
    const [tgStartParam, setStartParam] = useState(window.Telegram.WebApp.initDataUnsafe?.start_param);
    // const [tgStartParam, setStartParam] = useState("wallet=dev");
    const [telegramUserId, setTelegramUserId] = useState(null);
    const [tg_data, setTgtData] = useState();
    const [isPlanDataFetching, setIsPlanDataFetching] = useState(true);
    const [fetchMerge, setFetchMerge] = useState(false);
    const [wallet, setWallet] = useState(null);
    const {isLoading: MergeLoading} = useFetchMerge(wallet, UserStore.userId)
    console.log(tgStartParam)
    useEffect(() => {
        if (tgStartParam?.startsWith("wallet")) {
            setWallet(tgStartParam.split("=")[1])
            // console.log(tgStartParam.split("=")[1])
        }
    }, [tgStartParam]);

    const apiUrl = process.env.REACT_APP_API_URL;

    injectStores({
        UserStore,
        PaymnetStore,
        RouterStore
    });

    useEffect(() => {
        TG_WEB_APP.setHeaderColor('#000000');
        TG_WEB_APP.setBackgroundColor('#000000');
        // TG_WEB_APP.enableClosingConfirmation();
        TG_WEB_APP.ready();
        TG_WEB_APP.disableVerticalSwipes();
        TG_WEB_APP.expand();
        TG_WEB_APP.SettingsButton.isVisible = true
        TG_WEB_APP.SettingsButton.onClick(() => UserStore.setSettingWindow(true));
        if (TG_WEB_APP.version >= "8.0") {
            TG_WEB_APP.lockOrientation()
        }
        const lang_code = localStorage.getItem("lang_code")
        UserStore.setTelegramId(TG_WEB_APP.initDataUnsafe.user?.id)
        if (!lang_code) {
            userStore.setLangCode(TG_WEB_APP.initDataUnsafe.user?.language_code || 'en')
        } else {
            UserStore.setLangCode(lang_code)
        }

    }, [])

    const [isSettingsOpen, setSettingsOpen] = useState(false);


    const changePlanDataHandler = (data) => {
        setUserPlanData(data)
        // console.log(userPlanData)
    }

    const HEADERS = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    }

    // const telegramData = apiUrl === "http://localhost:8000/api" || "https://app.dev.deguard.io/api" ?
    //     {
    //         id: '785023632',
    //         first_name: "Mikhail",
    //         last_name: "Aleksin",
    //         username: "mrxnzxt",
    //         language_code: "ru"
    //     } :
    //     {
    //         id: String(TG_WEB_APP.initDataUnsafe.user?.id),
    //         first_name: TG_WEB_APP.initDataUnsafe.user?.first_name,
    //         last_name: TG_WEB_APP.initDataUnsafe.user?.last_name,
    //         username: TG_WEB_APP.initDataUnsafe.user?.username,
    //         language_code: TG_WEB_APP.initDataUnsafe.user?.language_code
    //     }

    const telegramData = {
        id: String(TG_WEB_APP.initDataUnsafe.user?.id),
        first_name: TG_WEB_APP.initDataUnsafe.user?.first_name,
        last_name: TG_WEB_APP.initDataUnsafe.user?.last_name,
        username: TG_WEB_APP.initDataUnsafe.user?.username,
        language_code: TG_WEB_APP.initDataUnsafe.user?.language_code
    }

    const { isLoading: isPartnersLoading} = usePartnerList(tgStartParam)

    const getPlanData = (innerUserId) => {
        setIsPlanDataFetching(true)
        fetch(`${apiUrl}/plans/get_all_plans/${innerUserId}`, {
            method: 'GET',
            headers: HEADERS
        }).then(res => {
                if (res.status === 400) {
                    setUserPlanData(false)
                    setIsPlanDataFetching(false)
                } else {
                    res.json().then(res => {
                        userStore.setUserPlanData(res)
                        setUserPlanData(res)
                        setIsPlanDataFetching(false)
                    })
                }
            }
        ).catch(res => {
            localStorage.removeItem("plan_data")
            setIsPlanDataFetching(false)
        })
    }

    const waitForPartnerId = async () => {
        while (!UserStore.partnerId) {
            await new Promise(resolve => setTimeout(resolve, 100)); // Ждём 100 мс
        }
        return UserStore.partnerId;
    };

    useEffect(() => {
        const fetchData = async () => {
            const res = await fetch(`${apiUrl}/miniapp/get_user_id/${telegramData.id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                },
            });
            const userResponse = await res.json();

            if (userResponse !== false) {
                userStore.setUserId(userResponse.user_id);
                setUserId(userResponse.user_id);
                getPlanData(userResponse.user_id);
            } else {
                let body = { ...telegramData };

                if (!tgStartParam?.startsWith('wallet')) {
                    console.log('Waiting for partnerId...');
                    const partnerId = await waitForPartnerId();
                    body.partner_id = partnerId;
                    console.log('Received partnerId:', partnerId);
                }

                const createRes = await fetch(`${apiUrl}/user/create/telegram_user`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                    },
                    body: JSON.stringify(body),
                });

                const createResponse = await createRes.json();

                if (createResponse.user_data) {
                    userStore.setUserId(createResponse.user_data.user_id);
                    setUserId(createResponse.user_data.user_id);
                    getPlanData(createResponse.user_data.user_id);
                    localStorage.setItem('user_id', createResponse.user_data.user_id);
                }
            }
        };

        fetch(`${apiUrl}/servers/available_servers`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
            },
        })
            .then(res => res.json())
            .then(res => {
                localStorage.setItem('servers', JSON.stringify(res))
            })
            .catch(err => console.log(err));

        fetchData();
    }, []);


    useEffect(() => {
        if (window.Telegram.WebApp.initDataUnsafe.start_param && !localStorage.getItem('isIncreaseBanner')) {
            fetch(`${apiUrl}/miniapp/increase_counter`, {
                method: 'POST',
                headers: HEADERS,
                body: JSON.stringify({"source": window.Telegram.WebApp.initDataUnsafe.start_param})
            })
                .then(res => res.json()
                    .then(res => {
                        localStorage.setItem('isIncreaseBanner', "true")
                    }))
                .catch(err => console.log(err))
        }
    }, [])

    const location = useLocation();

    const [displayLocation, setDisplayLocation] = useState(location);
    const [transitionStage, setTransistionStage] = useState("fadeIn");

    useEffect(() => {
        if (location !== displayLocation) setTransistionStage("fadeOut");
    }, [location, displayLocation]);


    if (process.env.REACT_APP_STAND === 'DEV' && !(['423417125','6940172990', '541456675', '349577070', '2200829547', '785023632', '426000871', '6928492759', '1534268906', '7039771033', '7850236321'].find(el => el === telegramData.id))) {
        return <h1 style={{textAlign: 'center'}}>Sorry, you don't have access</h1>;
    }
    // isPlanDataFetching, setIsPlanDataFetching

    useEffect(() => {
        if (location.pathname === displayLocation.pathname) {
            setTransistionStage("fadeIn");
        }
    }, [location, displayLocation]);


    // if (MergeLoading) {
    //     return <div>
    //         <h1>Merging...</h1>
    //     </div>
    // }

    return (
        <>
            {UserStore.visual_effects.snow_effects && <div className="snowblock"></div>}
            {UserStore.settingWindow && <Settings/>}
            {!UserStore.userId || isPlanDataFetching || isPartnersLoading ?
                <div className="centered-container">
                    <Lottie
                        className="awaiting_animation"
                        height="cover"
                        options={{
                            animationData: processingAnimation,
                            rendererSettings: {
                                preserveAspectRatio: 'xMidYMid slice'
                            }
                        }}
                    ></Lottie>
                </div> : <TonConnectUIProvider
                    manifestUrl="https://app.deguard.io/ton/tonconnect-manifest.json"
                    actionsConfiguration={{
                        twaReturnUrl: process.env.REACT_APP_TON_REDIRECT
                    }}
                >
                    <div className={`app page ${transitionStage}`}
                         onAnimationEnd={() => {
                             if (location.pathname !== displayLocation.pathname) {
                                 if (transitionStage === "fadeOut") {

                                     setTransistionStage("fadeIn");
                                     setDisplayLocation(location);
                                 }
                             }
                         }}>
                        {!isOnboarding ? <Onboardingpage setOnboarding={setOnboarding}/>
                            : (
                                <>
                                    <Routes location={displayLocation}>
                                        <Route path='/' element={<Homepage/>}/>
                                        <Route path='/vpn' element={<Vpnpage/>}/>
                                        <Route path='/tasks' element={<Taskspage/>}/>
                                        <Route path='/friends' element={<Friendspage/>}/>
                                        <Route path='/router' element={<RouterPage/>}/>
                                    </Routes>

                                </>
                            )
                        }
                    </div>
                    {isOnboarding && <Footer/>}
                </TonConnectUIProvider>}
        </>
    )
})


export default App;

