import axios from "axios";
import UserStore from "../../store/user-store";
import {useQuery} from "@tanstack/react-query";
import userStore from "../../store/user-store";
import {useState} from "react";
import {renderToStaticMarkup} from "react-dom/server";
const apiUrl = process.env.REACT_APP_API_URL;


const fetchTrialActivate = async () => {

    const body = {
        user_id: UserStore.userId,
        from_tg_bot: "true",
    }
    if (UserStore.partnerId) {
        body.from_partner = UserStore.partnerId;
    }
    return axios.post(`${apiUrl}/v2/plans/activate_trial/`, body, {
        headers: {Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`}
    })
}






export const useTrailActivate = (isBtnClicked) => {
    const [isQueryEnabled, setQueryEnabled] = useState(false);
    if (isBtnClicked && !isQueryEnabled) {
        setQueryEnabled(true);
    }

    const { data, isLoading } = useQuery({
        queryKey: ['activate_trial'],
        queryFn: () => fetchTrialActivate(),
        select: (response) => response.data,
        enabled: isQueryEnabled,
        onSuccess: (response) => {
            setQueryEnabled(false);
        },
    });

    if (isQueryEnabled) {
        window.Telegram.WebApp.HapticFeedback.notificationOccurred("success");
        UserStore.setPlanDataAfterPayment(data);
    }

    return { data, isLoading };
};